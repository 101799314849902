// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rewards-page {
  background-color: #131212;
  min-height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rewards-img{
  width: 100%;
}
.rd-bg{
  background-color: #393744;
  height: 60px;
  border-radius: 10px;
  padding: 10px 20px 10px 10px;
  margin-top: 10px;
}
.task-list{
  
  padding-top: 50px;
}
.telegram-img{
  width: 40px;
  border-radius: 10px;
  margin-left: 0px;
}
.telegram-img2{
  width: 30px;
  border-radius: 10px;
  margin-left: 0px;
}
.text-telegram{
  color: white;
  font-size: 15px;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/RewardsPage3.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,4BAA4B;EAC5B,gBAAgB;AAClB;AACA;;EAEE,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,eAAe;EACf,SAAS;EACT,yCAAyC;AAC3C","sourcesContent":[".rewards-page {\n  background-color: #131212;\n  min-height: 100vh;\n  color: white;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.rewards-img{\n  width: 100%;\n}\n.rd-bg{\n  background-color: #393744;\n  height: 60px;\n  border-radius: 10px;\n  padding: 10px 20px 10px 10px;\n  margin-top: 10px;\n}\n.task-list{\n  \n  padding-top: 50px;\n}\n.telegram-img{\n  width: 40px;\n  border-radius: 10px;\n  margin-left: 0px;\n}\n.telegram-img2{\n  width: 30px;\n  border-radius: 10px;\n  margin-left: 0px;\n}\n.text-telegram{\n  color: white;\n  font-size: 15px;\n  margin: 0;\n  font-family: Arial, Helvetica, sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
