import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Quizgame from './Quizgame';
import React from 'react';
import QuizgamePage1 from './QuizgamePage1';
import HomePage6 from './HomePage6';
import GamesPage2 from './GamesPage2';
import SettingsPage2 from './SettingsPage2';
import RewardsPage3 from './RewardsPage3';
import FriendQuizPage from './FriendsQuizPage';
import Quizgame1 from './Quizgame1';
import HomePage7 from './HomePage7';
import DailyRewardsPage from './DailyRewardsPage';
import Navigationbar from './Navigationbar';

function App() {
  return (
    <div className="App">
      
      <Router>
        <Routes>
          <Route path="/" element={<HomePage7 />} />
          <Route path="/settings" element={<SettingsPage2 />} />
          <Route path="/games" element={<GamesPage2 />} />
          <Route path="/quizpage1" element={<Quizgame />} />
          <Route path="/friendquiz" element={<FriendQuizPage />} />
          <Route path="/quizgamepage1" element={<Quizgame1 />} />
          <Route path="/rewards" element={<RewardsPage3 />} />
          <Route path="/dailyrewards" element={<DailyRewardsPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
