import React from 'react';
import { useNavigate } from 'react-router-dom';
import highlightBar from './assets/Highlight_Bar.png';
import dailyRewards from './assets/Daily_Rewards.png';
import rewards from './assets/Rewards.png';
import play from './assets/Play.png';
import settings from './assets/Settings.png';
import rectangleBar from './assets/Rectanglebar.png';
import homeIcon from './assets/Home.png';
import './Navigationbar.css';

const Navigationbar = ({ activeNav }) => {
  const navigate = useNavigate();

  const handleNavigation = (nav, path) => {
    localStorage.setItem('activeNav', nav);
    navigate(path);
  };

  return (
    <div className="bottom-navigation mt-auto">
      <img src={rectangleBar} alt="navigation bar" className="rectangle-bar img-fluid" />
      <div className="nav-items row mx-0 justify-content-center align-items-center">
        <div className="nav-item-container col-2 px-1 ">
          {activeNav === 'daily' && (
            <img src={highlightBar} alt="highlight" className="highlight-bar img-fluid" />
          )}
          <img
            src={dailyRewards}
            alt="Daily Rewards"
            onClick={() => handleNavigation('daily', '/dailyrewards')}
            className="nav-icon-d img-fluid"
          />
        </div>
        <div className="nav-item-container col-2 px-1">
          {activeNav === 'rewards' && (
            <img src={highlightBar} alt="highlight" className="highlight-bar img-fluid" />
          )}
          <img
            src={rewards}
            alt="Rewards"
            onClick={() => handleNavigation('rewards', '/rewards')}
            className="nav-icon img-fluid"
          />
        </div>
        <div className="nav-item-container col-2 px-1">
          {activeNav === 'home' && (
            <img src={highlightBar} alt="highlight" className="highlight-bar img-fluid" />
          )}
          <img
            src={homeIcon}
            alt="Home"
            onClick={() => handleNavigation('home', '/')}
            className="nav-icon-home img-fluid"
          />
        </div>
        <div className="nav-item-container col-2 px-1">
          {activeNav === 'play' && (
            <img src={highlightBar} alt="highlight" className="highlight-bar img-fluid" />
          )}
          <img
            src={play}
            alt="Play"
            onClick={() => handleNavigation('play', '/games')}
            className="nav-icon-p img-fluid"
          />
        </div>
        <div className="nav-item-container col-2 px-1">
          {activeNav === 'settings' && (
            <img src={highlightBar} alt="highlight" className="highlight-bar img-fluid" />
          )}
          <img
            src={settings}
            alt="Settings"
            onClick={() => handleNavigation('settings', '/settings')}
            className="nav-icon img-fluid"
          />
        </div>
      </div>
    </div>
  );
};

export default Navigationbar;
