import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SettingsPage2.css';
import Navigationbar from './Navigationbar';
import Rewards from './assets/Rewards-p.png';
import Settings from './assets/Settings_bar.png';
import Delete from './assets/delete.png';
import Logout from './assets/logout.png';
import Logout3 from './assets/logout3.png';
import Sound from './assets/sound.png';
import Warning from './assets/warning.png';

function SettingsPage2() {
  const [soundEnabled, setSoundEnabled] = useState(true);

  const handleLogout = () => {
    console.log('Logging out...');
    // Add your logout logic here
  };

  const handleSoundToggle = () => {
    setSoundEnabled(!soundEnabled);
    console.log('Sound:', !soundEnabled ? 'On' : 'Off');
    // Add your sound toggle logic here
  };

  const handleDeleteAccount = () => {
    const confirmed = window.confirm('Are you sure you want to delete your account? This action cannot be undone.');
    if (confirmed) {
      console.log('Deleting account...');
      // Add your delete account logic here
    }
  };
  const navigate = useNavigate();
  const handleExitClick = () => {
    console.log("clicked");
    navigate('/'); // Navigate to the homepage
  };

  return (
    <div className="settings-page">
      <div className="settings-header">
        <img src={Settings} alt="Rewards" className="settings-img"/>
      </div>

      <div className="row">
        <div className='col-1'></div>
        <div className='col-10 sd-bg'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center gap-3'>
              <img src={Sound} alt="Sound" className='settings-icon' style={{width: '40px'}}/>
              <div className='d-flex flex-column px-5'>
                <span className='settings-title text-white'>Sound</span>
                <span className='settings-subtitle'>On</span>
              </div>
            </div>
            <div className='setting-toggle'>
              <div className={`toggle-switch ${soundEnabled ? 'active' : ''}`} onClick={handleSoundToggle}>
                <div className='toggle-circle'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-1'></div>

        <div className='col-1'></div>
        <div className='col-10 sd-bg'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center gap-3'>
              <img src={Warning} alt="Logout" className='settings-icon' style={{width: '40px'}}/>
              <div className='d-flex flex-column px-5'>
                <span className='settings-title text-white'>Logout</span>
                <span className='settings-subtitle'>Exit account</span>
              </div>
            </div>
            <span className='text-white' style={{fontSize: '20px'}}>➜</span>
          </div>
        </div>
        <div className='col-1'></div>

        <div className='col-1'></div>
        <div className='col-10 sd-bg'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center gap-3'>
              <img src={Delete} alt="Delete" className='settings-icon' style={{width: '40px'}}/>
              <div className='d-flex flex-column px-5'>
                <span className='settings-title text-white'>Delete</span>
                <span className='settings-subtitle'> Delete account</span>
              </div>
            </div>
            <span className='text-white' style={{fontSize: '20px'}}>➜</span>
          </div>
        </div>
        <div className='col-1'></div>
      </div>

      <Navigationbar activeNav="settings" />
    </div>
  );
}

export default SettingsPage2;
