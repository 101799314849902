import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './QuizgamePage2.css';
import { createClient } from '@supabase/supabase-js';

const QuizgamePage1 = () => {
    const [quizType, setQuizType] = useState("");
    const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        const storedQuizType = localStorage.getItem('quizType');
        if (storedQuizType) {
            setQuizType(storedQuizType); // Retrieve quiz type from localStorage
        }
    }, []);

    const location = useLocation(); // Get the location object
    const navigate = useNavigate(); // Initialize navigate
    const { region, usersname } = location.state || {};

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [hasCompletedTask, setHasCompletedTask] = useState(false); // Track quiz completion
    const [isQuizCompleted, setIsQuizCompleted] = useState(false); // Track quiz completion status

    // Retrieve form data from localStorage or initialize with default values
    const [formData, setFormData] = useState(() => {
        const storedData = localStorage.getItem('formData');
        return storedData ? JSON.parse(storedData) : {
            dateofbirth: "",
            crickter: "",
            actor: "",
            car: "",
            bike: "",
            place: "",
            teacher: "",
            pet: "",
            hillstation: "",
            commonname: "",
        };
    });

    const questions = [
        { question: "What is your Date of Birth (dateofbirth)?", options: ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"], field: 'dateofbirth' },
        { question: "Who is your favourite cricketer?", options: ["", "Sachin Tendulkar", "Virat Kohli", "MS Dhoni", "Ricky Ponting"], field: 'crickter' },
        { question: "Who is your favourite actor?", options: ["", "Shah Rukh Khan", "Salman Khan", "Aamir Khan", "Ranbir Kapoor"], field: 'actor' },
        { question: "Who is your favourite teacher?", options: ["", "Mr. Smith", "Mrs. Johnson", "Mr. Brown", "Ms. Taylor"], field: 'teacher' },
        { question: "What is your favourite bike?", options: ["", "Harley Davidson", "Ducati", "Yamaha", "Kawasaki"], field: 'bike' },
        { question: "What is your favourite hill station?", options: ["", "Ooty", "Coorg", "Ladakh", "Manali"], field: 'hillstation' },
        { question: "What is your favourite pet?", options: ["", "Dog", "Cat", "Both"], field: 'pet' },
        { question: "What is your common name calling by friends?", options: ["", "Chintu", "Nani", "Chinna"], field: 'commonname' },
        { question: "What is your favourite place?", options: ["", "Hyderabad", "Bangalore", "Delhi", "Andhra Pradesh"], field: 'place' },
        { question: "What is your favourite car?", options: ["", "Porsche", "Ducati", "Lamborghini", "Audi"], field: 'car' },
    ];

    useEffect(() => {
        // Fetch user data from Supabase to check if the user has completed the quiz
        const checkIfUserHasCompletedTask = async () => {
            const SUPABASE_URL = 'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one/rest/v1/quizdata';
            const SUPABASE_ANON_KEY = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE';
            const SUPABASE_BEARER_KEY = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoic2VydmljZV9yb2xlIn0.lZoAKeF7q8Nxnp9Of82syKf3N2iJy-fiXWXByzOaHKE';
 const headers = {
                'Content-Type': 'application/json',
                'apikey': SUPABASE_ANON_KEY,
                'Authorization': SUPABASE_BEARER_KEY,
            };

            try {
                const response = await fetch(`${SUPABASE_URL}?username=eq.${usersname}`, { method: 'GET', headers: headers });
                const data = await response.json();

                if (data.length > 0) {
                    setHasCompletedTask(true); // User has completed the task
                }
            } catch (error) {
                console.error('Error checking user task status:', error);
            }
        };

        if (usersname) {
            checkIfUserHasCompletedTask();
        }
    }, [usersname]);

    const handleChange = (event) => {
        const { value } = event.target;
        const currentField = questions[currentQuestionIndex].field;
        const updatedFormData = { ...formData, [questions[currentQuestionIndex].field]: value };

        setFormData(updatedFormData);
        setSelectedOption(value);

        // Save the updated formData to localStorage
        localStorage.setItem('formData', JSON.stringify(updatedFormData));
    };

    const handleNext = () => {
        // Check if the user has selected an option
        const currentField = questions[currentQuestionIndex].field;
        if (!formData[currentField]) {
            alert("Please select an option"); // Display a popup alert
            return; // Prevent moving to the next question
        }
    
        // If the user has selected an option, proceed
       if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setSelectedOption(null); // Reset the selected option for the next question
        } else {
            postScoreToDatabase(); // Post data when quiz is complete
        }
    };
    

    const postScoreToDatabase = async () => {
        const SUPABASE_URL = 'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one/rest/v1/quizdata';
            const SUPABASE_ANON_KEY = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE';
            const SUPABASE_BEARER_KEY = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoic2VydmljZV9yb2xlIn0.lZoAKeF7q8Nxnp9Of82syKf3N2iJy-fiXWXByzOaHKE';
 const headers = {
                'Content-Type': 'application/json',
                'apikey': SUPABASE_ANON_KEY,
                'Authorization': SUPABASE_BEARER_KEY,
            };

        if (!formData.dateofbirth) {
            console.error('Please select a valid dateofbirth.');
            return;
        }

        try {
            const response = await fetch(SUPABASE_URL, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    username: usersname,
                    region: region,
                    ...formData, // Post the entire form data
                }),
            });

            if (response.ok) {
                console.log('Data posted successfully!');
                setIsQuizCompleted(true); // Mark the quiz as completed
                await postCoins(); // Post coins after quiz completion
            } 
            else {
                const error = await response.json();
                console.error('Error posting data:', error);
            }
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };


    


      // Create a different Supabase client for posting coins
      const supabase = createClient(
        'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one',
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE'
      );

    const [username, setUsername] = useState('');
    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
        } else {
            console.warn('Username not found in localStorage');
        }
    }, []);

    const postCoins = async () => {
        if (!username) {
            console.error('Username not set. Cannot post coins.');
            return;
        }

      

        try {
            // Fetch current coins for the user
            const { data: userData, error: fetchError } = await supabase
                .from('usersdata')
                .select('coins')
                .eq('username', username)
                .single();

            if (fetchError || !userData) {
                console.error('Error fetching user data:', fetchError);
                return;
            }

            // Add 100 coins to the user's total coins
            const updatedCoins = userData.coins + 100; // Assuming 100 coins are added

            // POST new coin data to the database
            const { error: postError } = await supabase
                .from('usersdata')
                .update({ coins: updatedCoins })
                .eq('username', username);

            if (postError) {
                console.error('Error posting coins:', postError);
            } else {
                console.log(`Coins posted successfully for ${username}: +100 coins`);
            }
        } catch (error) {
            console.error('Error during coin posting:', error);
        }
    };

    const handleBack = () => {
        navigate('/games'); // Navigate to home page on 'Back' button click
    };

    if (isQuizCompleted) {
        const handleShareOnTwitter = () => {
            const tweetText = `I just completed a quiz! 🎉 Congrats to me 😍! #QuizMaster #FunTime`;
            const quizURL = "https://example.com/myquiz"; // Replace with your quiz URL
            const twitterIntentURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(quizURL)}`;
            
            window.open(twitterIntentURL, "_blank");
        };

        const handleShareOnTelegram = () =>{
            const telegramText = `I just completed a quiz! 🎉 Congrats to me`;
            const telegramURL = "https://t.me/share/url?url=https://example.com/myquiz";
            const telegramIntentURL = `https://telegram.org/share/url?url=${encodeURIComponent(telegramURL)}`;
            window.open(telegramIntentURL, "_blank");
        }
    
        return (
            <div className="text-center">
                <h1>😍 Congrats {usersname}! Your Quiz is Ready!</h1>
    
                <div className='col-6'>
                    <img
                        src={require('./images/X.png')}
                        className="x-icon"
                        alt="Close"
                        onClick={handleShareOnTwitter}
                    />
                </div>
                <div className='col-6'>
                    <img
                        src={require('./images/Telegram.png')}
                        className="x-icon"
                        alt="Close"
                        onClick={handleShareOnTelegram}
                    />
                </div>
    
                <button className="btn btn-primary mt-3" onClick={handleBack}>
                    Back
                </button>
            </div>
        );
    }
    

    if (hasCompletedTask) {
        return (
            alert("You have already completd the quiz")
        );
    }

    return (
        <div>
            {quizType}
            <section id="question-section" className="buytoken d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className='col-md-12 text-center'>
                            <h1 className='lilita-one-regular'>Welcome to Quiz Questions</h1>
                            <div className='progress'>
                                <div
                                    className='progress-bar'
                                    style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }}
                                >
                                    {currentQuestionIndex + 1} / {questions.length}
                                </div>
                            </div>
                            <div className='question lilita-one-regular'>
                                <p>{questions[currentQuestionIndex].question}</p>

                                <select
                                    className='form-select'
                                    value={formData[questions[currentQuestionIndex].field] || ""}
                                    onChange={handleChange}
                                >
                                    {questions[currentQuestionIndex].options.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </select>

                                <button 
                                    className="btn btn-primary mt-3" 
                                    onClick={handleNext}
                                    
                                >
                                    {currentQuestionIndex < questions.length - 1 ? 'Next' : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default QuizgamePage1;
