import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import Navigationbar from './Navigationbar';
import './GamesPage2.css';
import Bestfriend from './assets/Best_friend_Quiz-Icon.png';
import Brother from './assets/Brothers-Quiz-icons.png';
import Friend from './assets/Friends-Quiz-icons.png';
import Mother from './assets/Mothers_Quiz-Icon.png';
import Sister from './assets/Sisters_Quiz-Icon.png';
import Father from './assets/Fathers_Quiz-Icon.png';
import Exit from './assets/Exit.png';
import Quiz from './assets/Quiz.png';

const GamesPage2 = () => {
    const navigate = useNavigate();

    const handleExitClick = () => {
        console.log("clicked");
        navigate('/'); // Navigate to the homepage
    };
    

    return (
        <div className="games-page">
      <div className="games-header">
          <img src={Quiz} alt="Quiz" className="quiz-img"/>
      </div>
      <div className='row mt-2'>
        <div className='col-6'>
          <img src={Bestfriend} alt="Bestfriend" className="bestfriend-img1"/>
        </div>
        <div className='col-6'>
          <img src={Friend} alt="Bestfriend" className="bestfriend-img2"/>
        </div>
        <div className='col-6'>
          <img src={Brother} alt="Bestfriend" className="bestfriend-img1"/>
        </div>
        <div className='col-6'>
          <img src={Sister} alt="Bestfriend" className="bestfriend-img2"/>
        </div>
        <div className='col-6'>
          <img src={Mother} alt="Bestfriend" className="bestfriend-img1"/>
        </div>
        <div className='col-6'>
          <img src={Father} alt="Bestfriend" className="bestfriend-img2"/>
        </div>
      </div>
      <Navigationbar activeNav="play" />
    </div>
    );
};

export default GamesPage2;
