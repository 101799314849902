// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .games-page{
    background-color: #1a1a2e;
    min-height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
  }
  .bestfriend-img1{
    width: 100%;
    margin: 0, auto;
    padding: 10px;
    margin-left: 10px;
  }
  .bestfriend-img2{
    width: 100%;
    margin: 0, auto;
    padding: 10px;
    margin-left: -20px;
  }
  .exit{
    width: 30px;
    position: absolute;
    left: 20px;
    top: 20px;
  }
  .quiz-img{
    width: 100%;
  }

  .games-header {
    position: relative;
    text-align: center;
  }

  .exit-button {
    background: none;
    border: none;
    padding: 0;
  }`, "",{"version":3,"sources":["webpack://./src/GamesPage2.css"],"names":[],"mappings":"EAAE;IACE,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,SAAS,EAAE,kBAAkB;IAC7B,UAAU,EAAE,mBAAmB;EACjC;EACA;IACE,WAAW;IACX,eAAe;IACf,aAAa;IACb,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,eAAe;IACf,aAAa;IACb,kBAAkB;EACpB;EACA;IACE,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,SAAS;EACX;EACA;IACE,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,UAAU;EACZ","sourcesContent":["  .games-page{\n    background-color: #1a1a2e;\n    min-height: 100vh;\n    color: white;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin: 0; /* Remove margin */\n    padding: 0; /* Remove padding */\n  }\n  .bestfriend-img1{\n    width: 100%;\n    margin: 0, auto;\n    padding: 10px;\n    margin-left: 10px;\n  }\n  .bestfriend-img2{\n    width: 100%;\n    margin: 0, auto;\n    padding: 10px;\n    margin-left: -20px;\n  }\n  .exit{\n    width: 30px;\n    position: absolute;\n    left: 20px;\n    top: 20px;\n  }\n  .quiz-img{\n    width: 100%;\n  }\n\n  .games-header {\n    position: relative;\n    text-align: center;\n  }\n\n  .exit-button {\n    background: none;\n    border: none;\n    padding: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
