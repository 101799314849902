// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dailyrewardspage {
    background-color: #131212;
    min-height: 100vh;
    color: white;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
.DailyRewards-img{
    width: 100%;
}
.rewards-description{
    font-family: Arial, Helvetica, sans-serif;
    font-size: bold;
    font-size: small;
}
.rewards-content{
    font-family: Arial, Helvetica, sans-serif;
    font-size: bold;
    font-size: medium;
    margin-top: 35px;
    text-align: center;
}
.dr-bg{
    background-color: #393744;
  height: 60px;
  border-radius: 10px;
}
.dr-bg:hover{
    background-color: green;
}
.rewards-bar{
    padding: 15px;
    margin-bottom: 10px;
    height: 120px;
}

.day-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: transform 0.3s;
}

.day-content.zoomed {
    transform: scale(1.1);
}

.coin-img {
    width: 30px;
    margin: 5px 0;
}
.container-daily{
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Dailyrewards.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,8BAA8B;EAChC;AACF;IACI,WAAW;AACf;AACA;IACI,yCAAyC;IACzC,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,yCAAyC;IACzC,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,yBAAyB;EAC3B,YAAY;EACZ,mBAAmB;AACrB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".dailyrewardspage {\n    background-color: #131212;\n    min-height: 100vh;\n    color: white;\n    margin: 0;\n    padding: 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n.DailyRewards-img{\n    width: 100%;\n}\n.rewards-description{\n    font-family: Arial, Helvetica, sans-serif;\n    font-size: bold;\n    font-size: small;\n}\n.rewards-content{\n    font-family: Arial, Helvetica, sans-serif;\n    font-size: bold;\n    font-size: medium;\n    margin-top: 35px;\n    text-align: center;\n}\n.dr-bg{\n    background-color: #393744;\n  height: 60px;\n  border-radius: 10px;\n}\n.dr-bg:hover{\n    background-color: green;\n}\n.rewards-bar{\n    padding: 15px;\n    margin-bottom: 10px;\n    height: 120px;\n}\n\n.day-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    transition: transform 0.3s;\n}\n\n.day-content.zoomed {\n    transform: scale(1.1);\n}\n\n.coin-img {\n    width: 30px;\n    margin: 5px 0;\n}\n.container-daily{\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
