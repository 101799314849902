import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dailyrewards.css';
import Dailyrewards from './assets/dailyrewards.png';
import Navigationbar from './Navigationbar';
import Coin from './assets/Coin.png';

const DailyRewardsPage = () => {
    const navigate = useNavigate();
    const [zoomedDay, setZoomedDay] = useState(null);

    const handleExitClick = () => {
      console.log("clicked");
      navigate('/'); // Navigate to the homepage
    };

    const handleDayClick = (day) => {
        setZoomedDay(day);
    };

    return (
        <div className='dailyrewardspage'>
            <div className="rewards-header">
                <img src={Dailyrewards} alt="Daily Rewards" className='DailyRewards-img'/>
            </div>
          
            <div className='row justify-content-center container-daily'>
                {[...Array(12)].map((_, index) => {
                    const day = index + 1;
                    const amount = day * 50;
                    return (
                        <div className='col-3 dr-bg rewards-bar mx-2 my-2' key={day} onClick={() => handleDayClick(day)}>
                            <div className={`day-content ${zoomedDay === day ? 'zoomed' : ''}`}> {/* Add zoom class based on state */}
                                <span>Day {day}</span>
                                <img src={Coin} alt="Coin" className='coin-img'/>
                                <span>{amount}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
            <Navigationbar activeNav="daily"/>
        </div>
    );
};

export default DailyRewardsPage;