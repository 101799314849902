import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home3.css';
import nameBar from './assets/Name_bar.png';
import coinsBar from './assets/Coins_bar.png';
import rectangleBar from './assets/Rectanglebar.png';
import dailyRewards from './assets/Daily_Rewards.png';
import rewards from './assets/Rewards.png';
import play from './assets/Play.png';
import settings from './assets/Settings.png';
import highlightBar from './assets/Highlight_Bar.png';
import imageBar from './assets/Image_bar.png';
import usericon from './assets/User_icon.png';

// Assuming you've already initialized supabase client
import { createClient } from '@supabase/supabase-js';
import Navigationbar from './Navigationbar';

// Supabase Client Initialization (Make sure to use your actual keys)
const supabase = createClient(
  'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one',
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE'
);

const HomePage7 = ({ coins }) => {
    const [username, setUsername] = useState('');
    const [localCoins, setLocalCoins] = useState(coins); // Local coins to track the coins being added
    const [fetchedCoins, setFetchedCoins] = useState(0); // Fetched coins from Supabase
    const [updatedCoins, setUpdatedCoins] = useState(0); // To store updated coins temporarily
    const [showGames, setShowGames] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showRewards, setShowRewards] = useState(false);
    const [activeNav, setActiveNav] = useState(
        localStorage.getItem('activeNav') || null
      );
    const navigate = useNavigate();
  
    // Get username from URL and store in localStorage for persistence
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const usernameParam = urlParams.get('username');
  
      if (usernameParam) {
        setUsername(usernameParam);
        localStorage.setItem('username', usernameParam); // Save username to localStorage
        fetchCoins(usernameParam); // Ensure to fetch coins when username is set
      } else {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
          setUsername(storedUsername); // Set username from localStorage
          fetchCoins(storedUsername); // Fetch coins for the stored username
        }
      }
    }, []); // Only run this once on mount
  
    useEffect(() => {
      if (username) {
        fetchCoins(username); // Fetch coins whenever the username is set
      }
    }, [username]);
  
    // Function to fetch coins from Supabase
    const fetchCoins = async (username) => {
      try {
        const { data, error } = await supabase
          .from('usersdata') // Specify the table
          .select('coins')
          .eq('username', username); // Filter by username
  
        if (error) {
          console.error('Error fetching coins:', error.message);
        } else if (data && data.length > 0) {
          setFetchedCoins(data[0].coins); // Set fetched coins from data
          setLocalCoins(0); // Reset localCoins to 0 when fetching
          console.log("coins fetched successfully");
        } else {
          console.warn('No coins found for the user');
        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    };
  
    // Function to update the coins in Supabase
    const postCoins = async () => {
      if (!username) {
        console.warn("Username is not set; update aborted.");
        return;
      }
  
      try {
        // Fetch the current coins value for the user
        const { data, error: fetchError } = await supabase
          .from('usersdata')
          .select('coins')
          .eq('username', username)
          .single();
  
        // Check if there was an error or no data returned
        if (fetchError) {
          console.error("Failed to fetch user's current coin data:", fetchError.message);
          return;
        }
  
        if (!data) {
          console.error("No user data found with the given username.");
          return;
        }
  
        console.log(localCoins)
        console.log(coins)
        console.log(data)
        console.log(fetchedCoins)
  
        // Add localCoins to the fetched coins (first time it will be 100)
        const updatedCoins = data.coins + localCoins;
  
        // Store the updated coins temporarily before resetting localCoins
        setUpdatedCoins(updatedCoins);
  
        // Update the coins in the database
        const { data: updateData, error: updateError } = await supabase
          .from('usersdata')
          .update({ coins: updatedCoins })
          .eq('username', username)
          .select(); // Retrieve updated data to confirm change
  
        if (updateError) {
          console.error("Failed to update coins:", updateError.message);
        } else if (updateData && updateData[0]) {
          console.log("Coins updated successfully in Supabase:", updateData[0].coins);
  
          // After successful update, update the displayed coins from the database
          setFetchedCoins(updateData[0].coins); // Update the displayed coins from the database
          setLocalCoins(0); // Reset localCoins after updating
          setUpdatedCoins(0); // Reset updatedCoins after update
          console.log(localCoins)
        } else {
          console.error("Update operation did not return any data.");
        }
      } catch (error) {
        console.error("Error updating coins:", error);
      }
    };
  
    // Trigger update when localCoins changes and username is set
    useEffect(() => {
      if (username && localCoins > 0) { // Only post if localCoins is greater than 0
        postCoins();
      }
    }, [localCoins, username]); // Include username in the dependency array
  

  const handleNavigation = (nav, path) => {
    setActiveNav(nav);
    localStorage.setItem('activeNav', nav);
    navigate(path);
  };

  return (
    <div className="home-page " style={{ backgroundColor: '#25232f' }}>
      <div className="top-bar row mx-0 px-2 py-3">
        <div className="user-profile col-6 px-6">
          <img src={usericon} alt="image bar" className="image-bar-g img-fluid" />
          <div className=" col-5 username">{username}</div>
        </div>
        <div className="coin-container col-5 px-2 d-flex justify-content-end">
          <img src={coinsBar} alt="coins" className="coins-bar img-fluid" />
          <span className="coin-value-v">{fetchedCoins}</span>
        </div>
      </div>
      <div>
        <h1 className='header'>QUIZ <br/> GAME</h1>
      </div>
      <Navigationbar activeNav="home" />
    </div>
  );
};

export default HomePage7;
