import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Quizgame1.css';
import { createClient } from '@supabase/supabase-js';

const Quizgame1 = () => {
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState(() => {
    const storedData = localStorage.getItem('formData');
    return storedData ? JSON.parse(storedData) : {
      dateofbirth: "",
      crickter: "",
      actor: "",
      car: "",
      bike: "",
      place: "",
      teacher: "",
      pet: "",
      hillstation: "",
      commonname: "",
    };
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { region, usersname } = location.state || {};

  const questions = [
    { question: "What is your Date of Birth?", options: ["January", "February", "March", "April"], field: 'dateofbirth' },
    { question: "Who is your favourite cricketer?", options: ["Sachin Tendulkar", "Virat Kohli", "MS Dhoni", "Ricky Ponting"], field: 'crickter' },
    { question: "Who is your favourite actor?", options: ["Shah Rukh Khan", "Salman Khan", "Aamir Khan", "Ranbir Kapoor"], field: 'actor' },
    { question: "Who is your favourite teacher?", options: ["Mr. Smith", "Mrs. Johnson", "Mr. Brown", "Ms. Taylor"], field: 'teacher' },
    { question: "What is your favourite bike?", options: ["Harley Davidson", "Ducati", "Yamaha", "Kawasaki"], field: 'bike' },
    { question: "What is your favourite hill station?", options: ["Ooty", "Coorg", "Ladakh", "Manali"], field: 'hillstation' },
    { question: "What is your favourite pet?", options: ["Dog", "Cat", "Both"], field: 'pet' },
    { question: "What is your common name your friends call you?", options: ["Chintu", "Nani", "Chinna"], field: 'commonname' },
    { question: "What is your favourite place?", options: ["Hyderabad", "Bangalore", "Delhi", "Andhra Pradesh"], field: 'place' },
    { question: "What is your favourite car?", options: ["Porsche", "Ducati", "Lamborghini", "Audi"], field: 'car' },
  ];


  const handleChange = (value) => {
    const currentField = questions[currentQuestionIndex].field;
    const updatedFormData = { ...formData, [currentField]: value };
    setFormData(updatedFormData);
    setSelectedOption(value);
    localStorage.setItem('formData', JSON.stringify(updatedFormData));
  };

  const handleNext = () => {
    const currentField = questions[currentQuestionIndex].field;
    if (!formData[currentField]) {
      alert("Please select an option");
      return;
    }

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption("");
    } else {
      postScoreToDatabase();
    }
  };

  const postScoreToDatabase = async () => {
    const SUPABASE_URL = 'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one/rest/v1/quizdata';
        const SUPABASE_ANON_KEY = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE';
        const SUPABASE_BEARER_KEY = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoic2VydmljZV9yb2xlIn0.lZoAKeF7q8Nxnp9Of82syKf3N2iJy-fiXWXByzOaHKE';
const headers = {
            'Content-Type': 'application/json',
            'apikey': SUPABASE_ANON_KEY,
            'Authorization': SUPABASE_BEARER_KEY,
        };

    if (!formData.dateofbirth) {
        console.error('Please select a valid dateofbirth.');
        return;
    }

    try {
        const response = await fetch(SUPABASE_URL, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                username: usersname,
                region: region,
                ...formData, // Post the entire form data
            }),
        });

        if (response.ok) {
            console.log('Data posted successfully!');
            setIsQuizCompleted(true); // Mark the quiz as completed
            await postCoins(); // Post coins after quiz completion
        } 
        else {
            const error = await response.json();
            console.error('Error posting data:', error);
        }
    } catch (error) {
        console.error('Error posting data:', error);
    }
};

//POSTING COINS IF USER SUBMITTED SUCCESSFULLY

const supabase = createClient(
    'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE'
  );

const [username, setUsername] = useState('');
useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
        setUsername(storedUsername);
    } else {
        console.warn('Username not found in localStorage');
    }
}, []);

const postCoins = async () => {
    if (!username) {
        console.error('Username not set. Cannot post coins.');
        return;
    }

  

    try {
        // Fetch current coins for the user
        const { data: userData, error: fetchError } = await supabase
            .from('usersdata')
            .select('coins')
            .eq('username', username)
            .single();

        if (fetchError || !userData) {
            console.error('Error fetching user data:', fetchError);
            return;
        }

        // Add 100 coins to the user's total coins
        const updatedCoins = userData.coins + 100; // Assuming 100 coins are added

        // POST new coin data to the database
        const { error: postError } = await supabase
            .from('usersdata')
            .update({ coins: updatedCoins })
            .eq('username', username);

        if (postError) {
            console.error('Error posting coins:', postError);
        } else {
            console.log(`Coins posted successfully for ${username}: +100 coins`);
        }
    } catch (error) {
        console.error('Error during coin posting:', error);
    }
};


if (isQuizCompleted) {
    const handleShareOnTwitter = () => {
        const tweetText = `I just completed a quiz! 🎉 Congrats to me 😍! #QuizMaster #FunTime`;
        const quizURL = "https://example.com/myquiz"; // Replace with your quiz URL
        const twitterIntentURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(quizURL)}`;
        
        window.open(twitterIntentURL, "_blank");
    };

    return (
        <div className="quiz-game-page">
        <div className="quiz-game-header">
          <button className="exit-button" onClick={() => navigate('/')}>
            ⬅️
          </button>
          </div>
        <div className="text-center col-md-6 congrats">
            <h1>😍 Congrats {usersname}! Your Quiz is Ready!</h1>

            <div className='col-6'>
                <img
                    src={require('./images/X.png')}
                    className="x-icon"
                    alt="Close"
                    onClick={handleShareOnTwitter}
                />
            </div>
        </div>
    
    </div>
        );
    }

  const currentQuestion = questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  return (
    <div className="quiz-game-page">
      <div className="quiz-game-header">
        <button className="exit-button" onClick={() => navigate('/')}>
          ⬅️
        </button>
        <div className="quiz-progress">
          Question {currentQuestionIndex + 1} of {questions.length}
        </div>
      </div>

      <div className="quiz-game-content">
        <div className="question-card">
          <h2 className="question-text">{currentQuestion.question}</h2>

          <div className="options-grid">
            {currentQuestion.options.map((option, index) => (
              <button
                key={index}
                className={`option-button ${selectedOption === option ? 'selected' : ''}`}
                onClick={() => handleChange(option)}
              >
                {option}
              </button>
            ))}
          </div>

          <button
            className="navigation-button"
            onClick={handleNext}
          >
            {isLastQuestion ? 'Submit Quiz' : 'Next Question'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Quizgame1;
