import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './FriendQuizPage.css';

function FriendQuizPage() {
  const [usersname, setUsersname] = useState('');
  const [region, setRegion] = useState('');
  const [hasCompletedTask, setHasCompletedTask] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/quizgamepage1', { state: { region, usersname } });
  };
  const handleExitClick = () => {
    navigate('/');
  };
  
  const regions = [
    { id: 'na', name: 'North America' },
    { id: 'sa', name: 'South America' },
    { id: 'eu', name: 'Europe' },
    { id: 'as', name: 'Asia' },
    { id: 'af', name: 'Africa' },
    { id: 'oc', name: 'India' }
  ];


  useEffect(() => {
    // Fetch user data from Supabase to check if the user has completed the quiz
    const checkIfUserHasCompletedTask = async () => {
        const SUPABASE_URL = 'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one/rest/v1/quizdata';
        const SUPABASE_ANON_KEY = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE';
        const SUPABASE_BEARER_KEY = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoic2VydmljZV9yb2xlIn0.lZoAKeF7q8Nxnp9Of82syKf3N2iJy-fiXWXByzOaHKE';
const headers = {
            'Content-Type': 'application/json',
            'apikey': SUPABASE_ANON_KEY,
            'Authorization': SUPABASE_BEARER_KEY,
        };

        try {
            const response = await fetch(`${SUPABASE_URL}?username=eq.${usersname}`, { method: 'GET', headers: headers });
            const data = await response.json();

            if (data.length > 0) {
                setHasCompletedTask(true); // User has completed the task
            }
        } catch (error) {
            console.error('Error checking user task status:', error);
        }
    };

    if (usersname) {
        checkIfUserHasCompletedTask();
    }
}, [usersname]);

if (hasCompletedTask) {
  return (
    <div className="friend-quiz-page">
      <div className="quiz-header">
    <button className="exit-button" onClick={handleExitClick}>
          <span className="exit-icon">⬅️</span>
        </button>
     
     </div>
     <div className='col-md-6 text-center'>
     <h1 className='text-complete'>Your quiz has completed successfully!Thankyou for visiting again</h1>
     </div>
  </div>

  );
}

  return (
    <div className="friend-quiz-page">
      <div className="quiz-header">
        <button className="exit-button" onClick={handleExitClick}>
          <span className="exit-icon">⬅️</span>
        </button>
        <h1>How Well Do Your Friends Know You?</h1>
      </div>

      <div className="quiz-content">
        <div className="instructions">
          <h2>Instructions:</h2>
          <ol>
            <li>Choose your region.</li>
            <li>Enter your name.</li>
            <li>Answer any 10 Questions about yourself.</li>
            <li>Your quiz-link will be ready.</li>
            <li>Share your quiz-link with your friends.</li>
            <li>Your friends will try to guess the right answers.</li>
            <li>Check the score of your friends at your quiz-link!</li>
          </ol>
        </div>

        <form className="quiz-form">
          <div className="form-group">
            <label htmlFor="username">Your Name</label>
            <input
              type="text"
              id="username"
              value={usersname}
              onChange={(e) => setUsersname(e.target.value)}
              placeholder="Enter your name"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="region">Your Region</label>
            <select
              id="region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              required
            >
              <option value="">Select your region</option>
              {regions.map(region => (
                <option key={region.id} value={region.name}>
                  {region.name}
                </option>
              ))}
            </select>
          </div>

          <button type="submit" className="submit-button" onClick={handleClick}>
            Start Quiz
          </button>
        </form>
      </div>
    </div>
  );
}

export default FriendQuizPage;
